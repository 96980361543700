<template>
  <div>
    <a-modal
      :destroyOnClose="true"
      :visible="visible"
      :title="isUpdateMode ? '修改产品大类' : '创建产品大类'"
      width="40%"
      centered
      okText="确认"
      cancelText="取消"
      @ok="() => onSubmit()"
      @cancel="() => hideDialog()"
    >
      <a-form-model layout="vertical" :rules="rules" :model="form" ref="form">
        <a-form-model-item label="产品大类名称" prop="name">
          <a-input width="100%" v-model="form.name" placeholder="请输入产品大类名称"> </a-input>
        </a-form-model-item>
        <a-form-model-item v-if="productCategories.length" label="产品目录" prop="productCategoryId">
          <a-select
            v-model="form.productCategoryId"
            allowClear
            placeholder="请选择产品目录"
            showSearch
            optionFilterProp="label"
          >
            <a-select-option
              v-for="(productCategorie, key) in productCategories"
              :key="key"
              :label="productCategorie.name"
              :value="productCategorie.id"
              >{{ productCategorie.name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';

export default {
  name: 'ProductClassesFormModal',
  components: {},
  mounted() {},
  data() {
    return {
      loadedDeviceTypeList: undefined,
      rules: {
        name: [{ required: true, message: '产品大类名称 不能为空', trigger: 'blur' }],
        productCategoryId: [{ required: true, message: '产品目录 不能为空', trigger: 'blur' }],
      },
      visible: false,
      isUpdateMode: false,
      resultCallBack: {
        onSubmit: Function,
      },
      productCategories: [],
      form: {
        name: undefined,
      },
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    hideDialog() {
      this.visible = false;
      this.form = {
        name: undefined,
      };
    },
    showCreateDialog(pararms, treeData, callback) {
      this.form = clonedeep(pararms);
      this.productCategories = treeData;
      this.isUpdateMode = false;
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    showUpdateDialog(pararms, treeData, callback) {
      this.isUpdateMode = true;
      this.productCategories = treeData;
      this.form = clonedeep(pararms);
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    onSubmit() {
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          this.resultCallBack.onSubmit(this.form);
          this.hideDialog();
        }
      });
    },
  },
};
</script>
